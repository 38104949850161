<template>
    <div class="vendorSign desktop">
        <Header :type="headerType"/> 
        <VendorDataForm :editType="editType"/>
    </div>
</template>
<script>
import Header from '@/components/Header'
import VendorDataForm from '@/components/VendorDataForm'
export default {
    name:'VenderSignup',
    data(){
        return {
            headerType:'empty',
            editType:'signup'
        }
    },
    components:{
        Header,VendorDataForm
    },
    computed:{

    }
}
</script>
<style lang="scss" scoped> 
    @import '@/assets/scss/_variables.scss';
    .vendorSign{
        padding-bottom:88px;
        // label{
        //     font-weight: 500;
        //     display: block;
        //     text-align: left;   
        //     font-size: 18px;
        //     margin-bottom: 10px;
        // }
        // .header{
        //     margin-bottom: 2%;
        // }
        // .bread{
        //     text-align: left;
        //     margin-left: 70px;
        //     font-size: 16px;
        //     margin-bottom: 50px;
        //     font-weight:400;
        // }
        // .form{
        //     margin-left: 157px;
        //     margin-bottom: 98px;
        // }
        // .formContainer{
        //     padding-left: 20px;
        //     display: flex;
        // }
        // .title{
        //     font-size: 28px;
        //     padding-left: 20px;
        //     border-left: 6px solid #6F52ED;
        //     text-align: left;
        //     margin-bottom: 35px;
        // }
        // .avatarWrap{
        //     width: 130px;
        //     position:relative;
        //     margin-right: 45px;
        //     img{
        //         position:absolute;
        //         top: 67%;
        //         right: -3%;
        //     }
        // }
        // .userAvatar{
        //     padding-bottom:130px;
        //     background-size: 100%;
        //     width: 130px;
        //     position:relative;
        // }
        // .inputWrap{
        //     margin-bottom: 32px;
        //     width: 500px;
        //     input{
        //         font-size: 16px;
        //         line-height: 3;
        //         padding-left: 15px;
        //         width: 100%;
        //     }
        // }
        // .btnWrap{
        //     button{
        //         width:244px;
        //         font-size:24px;
        //         line-height: 2.5;
        //         border-radius: 10px;
        //         margin: 0 52px;
        //     }
        // }
    }
</style>